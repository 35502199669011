<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    width="600"
    :before-close="handleClose"
  >
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      v-if="dialogVisible"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item label="姓名：" prop="userId">
        <!-- <el-input v-model="ruleForm.serviceName"
          maxlength="48"></el-input> -->
        <EmployeeSelect
          v-model="ruleForm.userId"
          placeholder="请选择"
          @change="changeValidateField"
        />
      </el-form-item>
      <el-form-item label="客服类型：" prop="type">
        <el-select
          v-model="ruleForm.type"
          style="width: 100%"
          placeholder="请选择"
        >
          <el-option
            v-for="item in typeList"
            :key="item.dictKey"
            :label="item.dictValue"
            :value="item.dictKey"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="手机号：" prop="servicePhone">
        <el-input v-model="ruleForm.servicePhone" maxlength="11"></el-input>
      </el-form-item>
      <el-form-item label="负责区域：" prop="area">
        <el-select
          v-model="ruleForm.area"
          style="width: 100%"
          multiple
          placeholder="请选择"
        >
          <el-option
            v-for="item in regionList"
            :key="item.label"
            :label="item.label"
            :value="item.label"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="排序：" prop="sort">
        <el-input v-model="ruleForm.sort" maxlength="48"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >确 定</el-button
        >
        <el-button @click="resetForm('ruleForm')">重 置</el-button>
      </el-form-item>
    </el-form>
    <!-- <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="handleClose" size="mini">确 定</el-button>
      <el-button @click="handleClose" size="mini">重 置</el-button>
    </span>-->
  </el-dialog>
</template>

<script>
import { saveCustomerService } from "@/api/SystemData";
import { provinceCity } from "@/utils/provinceCity";
import EmployeeSelect from "@/components/EmployeeSelect/index.vue";
import { dictionaryBatch } from "@/api/policy.js";
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    editData: {
      type: Object,
      default: () => {},
    },
  },
  components: { EmployeeSelect },
  watch: {
    dialogVisible: {
      handler(v) {
        if (v && this.editData.id) {
          this.ruleForm = JSON.parse(JSON.stringify(this.editData));
          this.ruleForm.area = this.ruleForm.area.split(",");
        } else if (!v) {
          this.ruleForm = {
            serviceName: "",
            userId: "",
            type: "",
            servicePhone: "",
            area: [],
            sort: "0",
          };
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      ruleForm: {
        serviceName: "",
        userId: "",
        type: "",
        servicePhone: "",
        area: [],
        sort: "0",
      },
      typeList: [],
      regionList: provinceCity,
      rules: {
        userId: [{ required: true, message: "请选择", trigger: "bulr" }],
        type: [
          { required: true, message: "请选择客服类型", trigger: "change" },
        ],
        servicePhone: [
          { required: true, message: "请输入手机号", trigger: "change" },
        ],
        area: [
          { required: true, message: "请选择负责区域", trigger: "change" },
        ],
      },
      loading: true,
      options: [],
    };
  },
  async created() {
    this.initSearch();
  },
  methods: {
    handleClose() {
      this.$emit("update:dialogVisible", false);
    },
    initSearch() {
      dictionaryBatch({ codes: "customerServiceType" }).then((res) => {
        const { customerServiceType } = res.data;
        this.typeList = customerServiceType;
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = JSON.parse(JSON.stringify(this.ruleForm));
          data.area = data.area.join(",");
          saveCustomerService({ ...data }).then((res) => {
            this.handleClose();
            this.$emit("changeList", 1);
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(type) {
      if (this.editData.id) {
        this.ruleForm = JSON.parse(JSON.stringify(this.editData));
      } else {
        this.ruleForm = {
          serviceName: "",
          userId: "",
          type: "",
          servicePhone: "",
          area: [],
          sort: "0",
        };
      }
    },
    changeValidateField(e, r) {
      this.ruleForm.serviceName = r;
      this.$refs["ruleForm"].validateField("userId");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  background: #4b7bca;
  .el-dialog__title {
    color: #fff;
  }
}
</style>
